import { MouseEventHandler } from "react";
import { AtlasLoading } from "../../loading";
import { AtlasValuesProps } from "../../value";

/**
 * Une option sélectionnable
 */
export interface AtlasOptionsItem {
  /**
   * L'identifiant de l'option
   */
  id: string;
  /**
   * Le contenu de l'option.
   * Généralement une simple string.
   * Peut accueillir des contenus complexes (ex: AtlasValues).
   */
  content: React.ReactNode;
  /**
   * L'action à éxécuter à la sélection de cette option
   */
  onClick: MouseEventHandler<HTMLButtonElement>;
}

/**
 * Un groupe d'options
 */
export interface AtlasOptionsGroup {
  /**
   * L'identifiant du groupe
   */
  id: string;
  /**
   * Le nom affiché du groupe
   */
  label: string;
  /**
   * Les options contenues dans le groupe
   */
  options: AtlasOptionsItem[];
}

export interface AtlasOptionsProps {
  /**
   * Les options.
   * Soit un tableau d'options, soit un tableau de groupe d'options.
   */
  options: AtlasOptionsItem[] | AtlasOptionsGroup[];
  /**
   * Un squelette de chargement à afficher (c'est à dire une entité sans valeur)
   */
  loader?: React.ReactElement<AtlasValuesProps>;
}

/**
 * Une liste d'options sélectionnables
 */
export function AtlasOptions(props: AtlasOptionsProps) {
  const loaders = props.loader ? (
    <AtlasLoading.Loaders>
      {[1, 2, 3].map((index) => (
        <div key={`loader-${index}`} className="atlas-options__optionWrapper">
          <div className="atlas-options__option">{props.loader}</div>
        </div>
      ))}
    </AtlasLoading.Loaders>
  ) : (
    <></>
  );

  const getOption = (option: AtlasOptionsItem): JSX.Element => {
    return (
      <li key={option.id} className="atlas-options__optionWrapper">
        <button
          className="atlas-options__option"
          type="button"
          onClick={(option as AtlasOptionsItem).onClick}
        >
          {(option as AtlasOptionsItem).content}
        </button>
      </li>
    );
  };

  if (!props.options.length) {
    return <div className="atlas-options">{loaders}</div>;
  } else if ("content" in props.options[0]) {
    return (
      <ul className="atlas-options">
        {props.options.map((option) => getOption(option as AtlasOptionsItem))}
      </ul>
    );
  } else {
    return (
      <div className="atlas-options">
        {loaders}

        <ul>
          {props.options.map((group) => (
            <li key={group.id}>
              <ul>
                <li id={group.id} className="atlas-options__groupLabel">
                  {(group as AtlasOptionsGroup).label}
                </li>

                {(group as AtlasOptionsGroup).options.map((option) =>
                  getOption(option as AtlasOptionsItem)
                )}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
