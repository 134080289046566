import {
  AtlasFieldTextProps,
  AtlasIcon,
  AtlasLoading,
  AtlasOptionsProps,
} from "atlas-ds";
import classNames from "classnames";
import { KeyboardEvent, useEffect, useRef, useState } from "react";

export interface AtlasSearchDropdownAddButton {
  /**
   * Le label du button
   */
  label: string;
  /**
   * L'action à éxécuter au clic
   */
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export interface AtlasSearchDropdownProps {
  /**
   * L'input de recherche
   */
  input: React.ReactElement<AtlasFieldTextProps>;
  /**
   * Les résultats.
   * Le composant AtlasOptions est séparé de celui-ci, car il peut
   * s'utiliser dans un contexte autre que AtlasSearchDropdown.
   * Un composant sur mesure peut également être utilisé.
   */
  children: React.ReactElement<AtlasOptionsProps> | React.ReactNode;
  /**
   * Le nombre de résultats.
   * 0 signifie que la recherche effectuée n'a pas retourné de résultats.
   * Pour une recherche non effectuée, omettez ce paramètre.
   */
  resultsCount?: number;
  /**
   * Le message affiché en cas d'absence de résultats.
   * Sans ce paramètre, un message par défaut sera affiché.
   */
  noResultsMessage?: string;
  /**
   * Ne jamais montrer le message d'absence de résultat.
   * Dans ce cas, l'absence de résultat devrait être annoncée par un autre
   * composant sur la page, possédant l'attribut aria-live="polite".
   */
  hideNoResultsMessage?: boolean;
  /**
   * Désactiver le champ
   */
  disabled?: boolean;
  /**
   * Une erreur (autre que l'absence de résultat).
   * Attention : l'input enfant doit également être désactivé.
   */
  error?: string;
  /**
   * Les résultats sont-ils en cours de chargement ?
   */
  loading?: boolean;
  /**
   * Un bouton permettant d'ajouter un élément en cas d'absence de résultat
   */
  addButton?: AtlasSearchDropdownAddButton;
  /**
   * L'action à éxécuter lorsque le composant perd le focus
   */
  onBlur?: React.FocusEventHandler;
}

/**
 * Un input de recherche dont les résultats s'affichent dynamiquement en dessous
 */
export function AtlasSearchDropdown(props: AtlasSearchDropdownProps) {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const paddingBottom = 32;
  const minHeight = 300;

  const hasContent =
    props.loading || props.error || typeof props.resultsCount !== "undefined";

  const [maxHeight, setMaxHeight] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  let closeTimeout: any;

  const [childInputIsDisabled, setChildInputIsDisabled] = useState(false);

  useEffect(() => {
    if (dropdownRef.current) {
      let maxHeight;

      const dropdownBox = dropdownRef.current.getBoundingClientRect();

      // Are we inside a <dialog> element ?
      const dialogContainer = dropdownRef.current?.closest("dialog");
      if (dialogContainer) {
        const dialogBox = dialogContainer.getBoundingClientRect();
        const dropdownDistanceFromDialogTop = dropdownBox.top - dialogBox.top;
        maxHeight = dialogBox.height - dropdownDistanceFromDialogTop;
      } else {
        maxHeight = window.innerHeight - dropdownBox.top;
      }

      setMaxHeight(`${Math.max(minHeight, maxHeight) - paddingBottom}px`);
    }

    setChildInputIsDisabled(!!props.input.props.disabled);
  });

  const onFocus = () => {
    if (childInputIsDisabled) return;

    setIsOpen(true);
    clearTimeout(closeTimeout);
  };

  const onBlur = (event: React.FocusEvent) => {
    closeTimeout = setTimeout(() => {
      setIsOpen(false);
      if (props.onBlur) {
        props.onBlur(event);
      }
    });
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsOpen(false);
    }
  };

  const onResultsClick = () => {
    setIsOpen(false);
  };

  const onAdd = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.addButton?.onClick(event);
    setIsOpen(false);
  };

  return (
    <AtlasLoading loading={!!props.loading}>
      <div
        className={classNames("atlas-searchDropdown", {
          "atlas-searchDropdown--open": isOpen,
        })}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
        tabIndex={-1}
      >
        {props.input}

        {!!hasContent && isOpen && (
          <div
            className="atlas-searchDropdown__dropdown"
            ref={dropdownRef}
            style={{ maxHeight }}
          >
            {props.error ||
            (props.resultsCount === 0 && !props.hideNoResultsMessage) ? (
              <>
                <div aria-live="polite" className="atlas-searchDropdown__error">
                  <p>
                    {props.error
                      ? props.error
                      : props.noResultsMessage ??
                        "Aucun résultat pour cette recherche."}
                  </p>
                  {!props.error && props.addButton && (
                    <button
                      className="atlas-searchDropdown__add"
                      onClick={onAdd}
                    >
                      <AtlasIcon name="plus" size="xs" />
                      {props.addButton.label}
                    </button>
                  )}
                </div>
              </>
            ) : (
              <>
                {!props.loading && (
                  <p
                    aria-live="polite"
                    className="atlas-searchDropdown__resultsCount"
                  >
                    {props.resultsCount} résultat
                    {props.resultsCount! > 1 ? "s" : ""}
                  </p>
                )}
                <div
                  className="atlas-searchDropdown__resultsWrapper"
                  onClick={onResultsClick}
                >
                  {props.children}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </AtlasLoading>
  );
}
